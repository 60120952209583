const defaultForm = () => {
  return {
    id: null,
    authen: false,
    authenObj: defaultAuthenObj(),
    status: null,
    verified: false,
    type: null,
    companyCode: null,
    staffId: null,
    name: null,
    nameChi: null,
    gender: null,
    identity: {
      type: 0,
      number: null,
      checksum: null,
    },
    birthday: null,
    phone: null,
    mobile: null,
    email: null,
    address1: null,
    address2: null,
    address3: null,
    district: null,
    area: null,
    country: null,
    language: null,
    photo: null,
    octopus: {
      number: null,
      checksum: null,
    },
    payment: {
      method: null,
      refNum: null
    },
    pickupStation: null
  };
};

const defaultAuthenObj = () => {
  return {
    companyCode: null,
    name: null,
    mobile: null,
    email: null
  }
}

export default {
  state: {
    locale: null,
    jwt: null,
    agreement: false,
    complete: false,
    form: defaultForm(),
  },
  getters: {
    formType: state => {
      return state.form.type;
    },
    personalJWT: state => {
      return state.jwt;
    }
  },
  mutations: {
    updatePersonalLocale(state, val) {
      state.locale = val
    },
    updateForm(state, val) {
      state.form = val;
    },
    updateFormType(state, val) {
      state.form.type = val;
    },
    updateAgreement(state, val) {
      state.agreement = val;
    },
    updatePersonalToken(state, val) {
      state.token = val;
    },
    updatePersonalJWT(state, val) {
      state.jwt = val;
    },
    updatePersonalAppNo(state, val) {
      state.form.id = val;
    },
    updateComplete(state, val) {
      state.complete = val;
    },
    updateStatusForPayment(state) {
      state.agreement = true;
      state.form.authen = true;
      state.form.verified = true;
    },
    updatePersonalPaymentMethod(state, obj) {
      state.form.payment.method = obj.method,
      state.form.payment.refNum = obj.refNum
    },
    clearPersonalPaymentRefNum(state) {
      state.form.payment.refNum = null;
    },
    resetPersonalForm(state) {
      let formObj = defaultForm();
      formObj.type = state.form.type
      formObj.id = state.form.id
      state.form = formObj;
    },
    resetPersonalState(state, removeJWT = true) {
      let formObj = defaultForm();
      if(removeJWT) {
        state.jwt = null;
      } else {
        formObj.type = state.form.type
      }
      state.agreement = false;
      state.locale = null;
      state.complete = false;
      state.form = formObj;
    },
    resetAuthen(state) {
      state.form.authen = false;
      state.form.authenObj = defaultAuthenObj();
    },
    resetAuthenObj(state) {
      state.form.authenObj = defaultAuthenObj();
    },
    updateIsMobile(state, val) {
      state.isMobile = val
    }
  },
};
