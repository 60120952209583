const stepList = {
  start: {
    viewName: "PersonalStart",
    routeName: "StepStart",
    requireAuthen: false,
    requireVerified: false,
    types: ["N", "R"]
  },
  agreement: {
    viewName: "PersonalAgreement",
    routeName: "StepAgreement",
    requireAuthen: false,
    requireVerified: false,
    types: ["N", "R"]
  },
  form: {
    viewName: "PersonalForm",
    routeName: "StepForm",
    requireAuthen: true,
    requireVerified: false,
    types: ["N", "R"]
    
  },
  confirm: {
    viewName: "PersonalConfirm",
    routeName: "StepConfirm",
    types: ["N", "R"],
    requireAuthen: true,
    requireVerified: true,
    i18nIndex: "form",
  },
  payment: {
    viewName: "PersonalPayment",
    routeName: "StepPayment",
    requireAuthen: true,
    requireVerified: true,
    types: ["N"]
  },
  process: {
    viewName: "PersonalPaymentProcess",
    routeName: "StepPaymentProcess",
    requireAuthen: false,
    requireVerified: false,
    types: ["N"],
    activeIndex: 4
  },
  complete: {
    viewName: "PersonalComplete",
    routeName: "StepComplete",
    requireAuthen: false,
    requireVerified: false,
    types: ["N", "R"]
  }
};

import router from "@/router";

export default {
  steps: [],
  initSteps(formType) {
    this.steps = [];
    for(let name in stepList) {
      let step = stepList[name]
      if (step.types.indexOf(formType) > -1) {
        step.name = name;
        this.steps.push(step);
      }
    }
  },
  getIndex(step) {
    if (step == null) return 0;
    return this.steps.findIndex(i => i.name == step.name);
  },
  getByViewName(viewName) {
    let array = this.steps.filter(step => step.viewName == viewName);
    return array.length > 0 ? array[0] : null;
  },
  getByRouteName(routeName) {
    let array = this.steps.filter(step => step.routeName == routeName);
    return array.length > 0 ? array[0] : null;
  },
  getByIndex(index) {
    if (index < 0) {
      index = 0;
    }
    if (index > this.steps.length -1) {
      index = this.steps.length -1;
    }
    return this.steps[index];
  },
  goFirst() {
    let step = this.getByIndex(0);
    this.goStep(step);
  },
  goForm() {
    let step = this.getByIndex(2);
    this.goStep(step);
  },
  goPrev(step) {
    let index = this.getIndex(step);
    let prev = this.getByIndex(index - 1);
    this.goStep(prev);
  },
  goNext(step) {
    let index = this.getIndex(step);
    let next = this.getByIndex(index + 1);
    this.goStep(next);
  },
  goStep(step) {
    if (step && step.routeName) {
      router.push({
        name: step.routeName,
      });
    }
  }
};
