import { createI18n } from "vue-i18n/dist/vue-i18n.runtime.esm-bundler";
//You can use "vue-i18n/index" as a placeholder value during debugging, 
// but when pushing the code, please use the actual value "vue-i18n/dist/vue-i18n.runtime.esm-bundler"
// to ensure that it does not trigger CSP security issues.

const i18n = createI18n({
  locale: "en",
  messages: {
    ch: require("@/language/ch.json"),
    en: require("@/language/en.json"),
  },
});

export default i18n;
