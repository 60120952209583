import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import personal from "./personal";
import company from "./company";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    personal,
    company
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      // storage: window.navigator.userAgent.indexOf("Macintosh") > -1 && window.navigator.vendor.indexOf("Apple") > -1 ? window.localStorage : window.sessionStorage,
      key: "personal",
      paths: ["personal"]
    }),
    createPersistedState({
      storage: window.sessionStorage,
      // storage: window.navigator.userAgent.indexOf("Macintosh") > -1 && window.navigator.vendor.indexOf("Apple") > -1 ? window.localStorage : window.sessionStorage,
      key: "company",
      paths: ["company"],
    })
  ],
  strict: false,
});
