import http from "@/http"

let modulePath = "personal/Authenticate";

const post = function(path, data, config = {}) {
  return http.post(`${modulePath}/${path}`, data, config);
}

export default  {
  getAuthenticate(token) {
    return http.get(`${modulePath}`, {
      params: {
        token: token
      }
    })
  },
  postRefreshJWT(jwt) {
    return post("refresh", null, {
      headers: {
        Authorization: "Bearer " + jwt
      }
    })
  },
  postValidate(jwt, dataObj) {
    return post("validate", dataObj, {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json"
      },
      data: dataObj
    })
  }
}
