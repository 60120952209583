import http from "@/http"
import { parseJWT } from "../../../libs/common";

let modulePath = "company/Registration";

const post = function(path, data, config = {}) {
  return http.post(`${modulePath}/${path}`, data, config);
}

const get = function(path, config = {}) {
  return http.get(`${modulePath}/${path}`,config);
}

export default {
  getRegistrationEnquiry(jwt, enqParamsObj) {
    return get("enquiry", {
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: enqParamsObj
    })
  },
  postSave(jwt, formType, items) {
    let jwtObj = parseJWT(jwt);
    let dataObj = {
      companyCode: jwtObj["co-code"],
      updateUser: jwtObj.staff,
      type: formType,
      items: items
    }
    return post("save", dataObj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    })
  },
  postEdit(jwt, item) {
    return post("edit", item, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    })
  },
  postRecordCancel(jwt, token) {
    return post("cancel", null, {
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: {
        token: token
      }
    })
  },
  postRecordResend(jwt, token) {
    return post("resend", null, {
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: {
        token: token
      }
    })
  }
}