export default {
  state: {
    jwt: null,
    agreement: false,
    list: [],
    searchObj: initSearchObj(),
    searchResignObj: initSearchResignObj(),
    toBeTerminateStaffList: []
  },
  getters: {
    getList: state => {
      return state.list
    },
    getToBeTerminateStaffList: state => {
      return state.toBeTerminateStaffList
    },
    companyJWT: state => {
      return state.jwt
    },
    searchObj: state => {
      return state.searchObj
    },
    searchResignObj: state => {
      return state.searchResignObj
    }
  },
  mutations: {
    updateList(state, val) {
      state.list = val
    },
    updateToBeTerminateStaffList(state, val) {
      state.toBeTerminateStaffList = val
    },
    clearList(state) {
      state.list = []
    },
    clearToBeTerminateStaffList(state) {
      state.toBeTerminateStaffList = []
    },
    updateCompanyAgreement(state) {
      state.agreement = true
    },
    resetCompanyState(state) {
      state.jwt = null
      state.list = []
      state.toBeTerminateStaffList = []
      state.searchObj = initSearchObj()
      state.searchResignObj = initSearchResignObj()
      state.agreement = false
    },
    updateCompanyJWT(state, val) {
      state.jwt = val
    },
    updateSearchObj(state, val) {
      state.searchObj = val
    },
    updateSearchResignObj(state, val) {
      state.searchResignObj = val
    },
    clearSearchObj(state) {
      state.searchObj = initSearchObj()
    },
    clearSearchResignObj(state) {
      state.searchResignObj = initSearchResignObj()
    }
  }
}

function initSearchObj() {
  return {
    name: null,
    mobile: null,
    email: null,
    status: null,
    createDateStart: null,
    createDateEnd: null
  }
}

function initSearchResignObj() {
  return {
    name: null,
    identityNumber: null,
    status: null,
    createDateStart: null,
    createDateEnd: null
  }
}
