<script>

export default {
  name: "TableView",
  data() {
    return {
    }
  },
  methods: {
    th(key) {
      return this.t("headers", key)
    },
    tb(key) {
      return this.t("body", key)
    },
    tbm(key) {
      return this.tm("body", key)
    },
    t(section,key) {
      return this.$i18n.t(`company.table.${section}.${key}`)
    },
    tm(section,key) {
      return this.$i18n.tm(`company.table.${section}.${key}`)
    }
  },
  computed: {
    formType() {
      return this.$store.getters.formType;
    }
  }
};
</script>