import { read, utils } from "xlsx";

export function ReadExcel(excel, options={}, callback) {
  let reader = new FileReader();
  reader.onload = function (e) {
    let data = e.target.result;
    let workbook = read(data, { type: "binary" });
    let sheetNames = workbook.SheetNames;
    let sheet = workbook.Sheets[sheetNames[0]];
    let json = utils.sheet_to_json(sheet, options);
    callback(json);
  };

  reader.readAsBinaryString(excel);
}
