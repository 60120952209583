import http from "@/http"

let modulePath = "personal/Payment";

const post = function(path, data, config = {}) {
  return http.post(`${modulePath}/${path}`, data, config);
}

const put = function(path, data, config = {}) {
  return http.put(`${modulePath}/${path}`, data, config);
}

export default  {
  postCreatePayment(jwt, paymentMethod, formId) {
    let data = new FormData();
    data.append("formId", formId);
    data.append("method", paymentMethod);
    return post("create", data, {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "multipart/form-data"
      }
    })
  },
  postConfirmPayment(jwt, orderId, formId) {
    let data = new FormData();
    data.append("formId", formId);
    data.append("orderId", orderId);
    return post("confirm", data, {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "multipart/form-data"
      }
    })
  },
  checkPayment(jwt, formId) {
    let data = new FormData();
    data.append("formId", formId);
    return put("payment", data, {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "multipart/form-data"
      }
    })
  }
}