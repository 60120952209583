import { ElMessage, ElMessageBox } from "element-plus";
import $i18n from "../language/index.js";
import { mergeObject } from "@/libs/common";

let i18n = $i18n.global;
let info = "info";
let success = "success";
let warning = "warning";
let error = "error";

export function DefaultMessage(message) {
  ElMessage(message);
}

export function SuccessMessage(message) {
  $message(message, success);
}

export function WarningMessage(message) {
  $message(message, warning);
}

export function ErrorMessage(message) {
  $message(message, error);
}

const $message = function (message, type) {
  ElMessage({
    message: message,
    type: type,
  });
};

export function Alert(message, title) {
  ElMessageBox.alert(message, title);
}

export function WarningAlertWithCallback(message, title, callback) {
  $alert(message, title, warning, callback);
}

export function InfoAlert(message, title) {
  $alert(message, title, info);
}

export function SuccessAlert(message, title) {
  $alert(message, title, success);
}

export function WarningAlert(message, title) {
  $alert(message, title, warning);
}

export function ErrorAlert(message, title) {
  $alert(message, title, error);
}

const $alert = function (message, title, type, additionalOptions) {
  let options = {
    type: type,
    customClass: "messageBox"
  }
  if (additionalOptions) {
    options = mergeObject(options, additionalOptions)
  }
  ElMessageBox.alert(message, title, options);
};

export function ConfirmDialog(
  title,
  message,
  confirmFun,
  cancelFun,
  finallyFun,
  options
) {
  let defaultOptions = {
    confirmButtonText: i18n.t("common.button.ok"),
    cancelButtonText: i18n.t("common.button.cancel"),
    type: "warning",
    customClass: "messageBox",
    showClose: true,
    closeOnClickModal: false,
    closeOnPressEscape: false
  }
  if (options) {
    defaultOptions = mergeObject(defaultOptions, options)
  }
  ElMessageBox.confirm(message, title, defaultOptions)
    .then(confirmFun)
    .catch(cancelFun)
    .finally(finallyFun);
}
