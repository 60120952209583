const constants = {
  locale: {
    en: "en",
    ch: "ch",
  },
  formType: {
    new: "N",
    renew: "R"
  },
  links: {
    personal: {
      faq: "https://www.mtr.com.hk/archive/ch/tickets/airport_scheme_online_application_faq.pdf"
    },
    company: {
      faq: "https://www.mtr.com.hk/archive/ch/tickets/airport_scheme_company_management_portal_faq.pdf",
      tutorial: {
        en: "https://www.mtr.com.hk/archive/ch/tickets/airport_scheme_company_portal_tutorials_en.pdf",
        ch: "https://www.mtr.com.hk/archive/ch/tickets/airport_scheme_company_portal_tutorials_ch.pdf"
      },
      form: {
        registration: "https://www.mtr.com.hk/archive/ch/tickets/airport_scheme_company_registration_form.pdf"
      }
    },
    common: {
      maintenance: "https://www.mtr.com.hk/maintenance_airport_staff_travel_scheme.html"
    }
  },
  unit: {
    character: ["character", "characters"],
    digit: ["digit", "digits"],
    year: ["year old", "years old"],
    month: ["month", "months"],
    entry: ["entry", "entries"]
  },
};

export default constants;
