<template>
  <div class="company-new">
    <div class="title">
      <h3>{{ $t(`company.menu.${type}`) }}</h3>
      <el-divider />
    </div>
    <el-row class="toolbar">
      <el-col :span="24"></el-col>
      <el-col :span="12" class="left">
        <el-button type="primary" size="medium" plain @click="btnAddOnClick">
          <el-icon class="el-icon--left">
            <!-- <font-awesome-icon icon="user-plus" /> -->
            <i class="bi bi-person-plus-fill"></i>
          </el-icon>
          <span>{{ $t("common.button.add") }}</span>
        </el-button>
      </el-col>
      <el-col :span="12" class="right">
        <el-button type="default" size="medium" plain @click="btnImportOnClick">
          <el-icon class="el-icon--left">
            <!-- <font-awesome-icon icon="file-upload" /> -->
            <i class="bi bi-file-earmark-arrow-up-fill"></i>
          </el-icon>
          <span>{{ $t("common.button.import") }}</span>
        </el-button>
      </el-col>
    </el-row>
    <div class="table">
      <el-table :data="table.list" @selection-change="selectionChange" v-loading="table.loading"
        :row-class-name="tableRowClassName" :empty-text="this.$i18n.t('company.enquiry.noData')">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column width="50">
          <template #default="scope">
            <el-tooltip v-if="scope.row.errors.length > 0" effect="light" popper-class="validation-err-popper">
              <el-icon class="validation-error"><circle-close-filled /></el-icon>
              <template #content>
                <p style="font-size:14px;" v-for="(msg, index) in scope.row.errors" :key="index">
                  {{ msg }}
                </p>
              </template>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="th('name')" prop="name" min-width="250"></el-table-column>
        <el-table-column :label="th('mobile')" prop="mobile" min-width="120"></el-table-column>
        <el-table-column :label="th('email')" prop="email" min-width="300"></el-table-column>
        <el-table-column fixed="right" width="120">
          <template #default="scope">
            <div style="text-align:right;">
              <el-tooltip :content="$t('common.button.edit')" placement="top">
                <el-button type="primary" circle plain @click="rowEdit($event, scope.$index, scope.row)">
                  <el-icon class="el-icon">
                    <!-- <font-awesome-icon icon="user-edit" /> -->
                    <i class="bi bi-pencil-fill"></i>
                  </el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('common.button.delete')" placement="top">
                <el-button type="danger" circle plain @click="rowDelete($event, scope.$index, scope.row)">
                  <el-icon class="el-icon">
                    <!-- <font-awesome-icon icon="user-slash" /> -->
                    <i class="bi bi-person-fill-slash"></i>
                  </el-icon>
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-footer" v-if="table.list.length > 0">
        <el-button style="margin-right:20px;" type="danger" size="medium" plain @click="btnDeleteOnClick"
          v-if="table.selectionList.length">
          <el-icon class="el-icon--left">
            <!-- <font-awesome-icon icon="users-slash" /> -->
            <i class="bi bi-person-fill-slash"></i>
          </el-icon>
          <span>{{ $t("common.button.delete") }}</span>
        </el-button>
        <el-button type="primary" @click="btnSubmitOnClick" :disabled="table.submitBtnDisable">
          <el-icon>
            <!-- <font-awesome-icon icon="save" /> -->
            <i class="bi bi-floppy-fill"></i>
          </el-icon>
          <span>{{ $t("common.button.submit") }}</span>
        </el-button>
      </div>
    </div>

    <el-dialog @close="v$.recordDialog.staff.$reset()" v-model="recordDialog.display" custom-class="staff-dialog">
      <template #title>
        <p class="staff-dialog-header">{{ this.recordDialog.title }}</p>
        <p class="staff-dialog-header">{{ this.recordDialog.text }}</p>
      </template>
      <template #>
        <el-form class="add-staff-form">
          <el-form-item :label="th('name')" :class="isInvalid(v$.recordDialog.staff.name.$error)">
            <el-input :maxlength="25" v-model="recordDialog.staff.name"
              :placeholder="$t('company.dialog.staffDialog.placeholder.name')"
              @focus="v$.recordDialog.staff.name.$reset()"
              @blur="triggerValidate($event, v$.recordDialog.staff.name, 'upper')" @keypress="handleSubmit" />
            <div class="invalid-msg" v-if="v$.recordDialog.staff.name.$error">{{
              v$.recordDialog.staff.name.$errors[0].$message }}</div>
          </el-form-item>
          <el-form-item :label="th('mobile')" :class="isInvalid(v$.recordDialog.staff.mobile.$error)">
            <el-input :maxlength="8" type="tel" v-model="recordDialog.staff.mobile"
              :placeholder="$t('company.dialog.staffDialog.placeholder.mobile')"
              @focus="v$.recordDialog.staff.mobile.$reset()" @blur="triggerValidate($event, v$.recordDialog.staff.mobile)"
              @keypress="handleSubmit" />
            <div class="invalid-msg" v-if="v$.recordDialog.staff.mobile.$error">{{
              v$.recordDialog.staff.mobile.$errors[0].$message }}</div>
          </el-form-item>
          <el-form-item :label="th('email')" :class="isInvalid(v$.recordDialog.staff.email.$error)">
            <el-input :maxlength="40" type="email" v-model="recordDialog.staff.email"
              :placeholder="$t('company.dialog.staffDialog.placeholder.email')"
              @focus="v$.recordDialog.staff.email.$reset()"
              @blur="triggerValidate($event, v$.recordDialog.staff.email, 'lower')" @keypress="handleSubmit" />
            <div class="invalid-msg" v-if="v$.recordDialog.staff.email.$error">{{
              v$.recordDialog.staff.email.$errors[0].$message }}</div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <el-button type="default" @click="dialogClose(recordDialog)">
          <span>{{ $t("common.button.cancel") }}</span>
        </el-button>
        <el-button type="primary" @click="dialogConfirm">
          <span>{{ $t("common.button.confirm") }}</span>
        </el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="uploadDialog.display" @open="onUploadDialogShow">
      <template #default>

        <el-steps direction="vertical" :active="0">
          <el-step :title="$t('company.new.upload.steps.1.title')">
            <template #description>
              <p style="font-size:14px;word-break: break-word;">{{ $t('company.new.upload.steps.1.content') }}</p>
              <el-link type="primary" style="margin-bottom:20px;"
                href="/static/MTR%20ASDTS%20Bulk%20upload%20template.xlsx" download>
                {{ $t('company.new.upload.templateName') }}
              </el-link>
            </template>
          </el-step>
          <el-step :title="$t('company.new.upload.steps.2.title')">
            <template #description>
              <p style="word-break:break-word;">{{ $t('company.new.upload.steps.2.content') }}</p>
            </template>
          </el-step>
          <el-step :title="$t('company.new.upload.steps.3.title')">
            <template #description>
              <p>{{ $t('company.new.upload.steps.3.content') }}</p>
              <el-upload ref="upload" :drag="true" action="" :before-upload="handleUpload" :auto-upload="false"
                :multiple="false" :on-change="handleChange" :file-list="uploadDialog.fileList">
                <el-icon class="el-icon--upload">
                  <!-- <font-awesome-icon icon="cloud-upload-alt" /> -->
                  <i class="bi bi-cloud-arrow-up-fill"></i>
                </el-icon>
                <div class="el-upload__text">
                  {{ $t("company.new.upload.textDrop") }}
                  <em>{{ $t("company.new.upload.textEm") }}</em>
                </div>
              </el-upload>
            </template>
          </el-step>
        </el-steps>
      </template>
      <template #footer>
        <el-button type="primary" @click="uploadDialogConfirm">
          <span>{{ $t("common.button.submit") }}</span>
        </el-button>
      </template>
    </el-dialog>
  </div>
  <!-- <button @click="test">Test</button> -->
</template>

<style scoped>

.toolbar,
.title {
  margin: 10px 20px;
}

.toolbar .el-button {
  min-width: 100px;
}

.toolbar .right {
  text-align: right;
}

.table-footer {
  background-color: white;
  margin: 0;
  padding: 10px 20px;
  text-align: right;
}

.table {
  margin: 0px 20px 20px 20px;
}

.company-new>>>.el-table {
  width: 100%;
}

.company-new>>>.el-table__append-wrapper {
  text-align: right;
  padding: 10px;
}

.company-new>>>.el-form-item__label {
  width: 80px;
}

.company-new>>>.el-upload,
.company-new>>>.el-upload .el-upload-dragger {
  width: 100%;
}

.company-new>>>.el-step__head.is-wait,
.company-new>>>.el-step__title.is-wait,
.company-new>>>.el-step__description.is-wait {
  color: var(--el-text-color-primary);
  border-color: var(--el-text-color-primary);
  font-size: 14px;
}

.company-new>>>.el-step__title.is-wait {
  font-size: 16px;
  font-weight: bold;
}

.company-new>>>.error-row {
  --el-table-tr-bg-color: var(--el-color-danger-light);
}

.company-new>>>.validation-error {
  color: red;
  font-size: 20px;
  line-height: 2;
}

.company-new>>>.add-staff-form .el-form-item {
  display: block;
  margin: 0;
}

.company-new>>>.staff-dialog .el-dialog__body {
  padding: 10px 20px;
}

.company-new>>>.staff-dialog-header {
  margin: 5px 0px 0px 0px;
  font-weight: bold;
}
</style>

<script>
import useVuelidate from '@vuelidate/core'
import * as validators from '@/libs/i18n-validators'
import { SuccessMessage, WarningMessage, ConfirmDialog, ErrorMessage, WarningAlert } from "../../plugins/message.js";
import { ReadExcel } from "../../plugins/sheetjs.js";
import * as common from "@/libs/common"
import registration from "@/http/apis/company/registration"
import TableView from './TableView.vue';
import constants from "@/libs/constants";
import { i18nPluralizationBase } from '../../libs/common.js';

export default {
  name: "CompanyNew",
  extends: TableView,
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true,
        $autoDirty: false
      })
    }
  },
  validations() {
    return {
      recordDialog: {
        staff: {
          name: {
            required: validators.required,
            checkLang: validators.checkLang({ lang: "E", allowNumber: false, allowSpacing: true, allowSymbol: false }),
          },
          email: {
            required: validators.required,
            maxLength: validators.maxLength({
              limit: { num: 40 },
              unit: { num: constants.unit.character }
            }),
            email: validators.email
          },
          mobile: {
            required: validators.required,
            numeric: validators.numeric,
            exactLength: validators.exactLength({
              limit: { num: 8 },
              unit: { num: constants.unit.digit }
            })
          }
        }
      },
      uploadDialog: {
        staff: {
          name: {
            required: validators.required,
            checkLang: validators.checkLang({ lang: "E", allowNumber: false, allowSpacing: true, allowSymbol: false }),
          },
          email: {
            required: validators.required,
            maxLength: validators.maxLength({
              limit: { num: 40 },
              unit: { num: constants.unit.character }
            }),
            email: validators.email
          },
          mobile: {
            required: validators.required,
            numeric: validators.numeric,
            exactLength: validators.exactLength({
              limit: { num: 8 },
              unit: { num: constants.unit.digit }
            })
          }
        }
      }
    }
  },
  data() {
    return {
      showView: false,
      table: {
        list: [],
        selectionList: [],
        loading: false,
        submitBtnDisable: false
      },
      recordDialog: {
        display: false,
        title: "",
        text: "",
        isAdd: true,
        staff: {
          name: null,
          mobile: null,
          email: null,
          errors: []
        },
        originStaff: null
      },
      uploadDialog: {
        display: false,
        fileList: [],
        staff: this.initStaffObj()
      }
    }
  },
  computed: {
    type() {
      let pathArr = this.$router.currentRoute.value.path.split("/");
      return pathArr[pathArr.length - 1]
    }
  },
  created() {
    this.loadData();
  },
  beforeRouteUpdate() {
    this.loadData();
  },
  methods: {
    // Toolbar
    btnAddOnClick(event) {
      this.openDialog(true, {
        name: "",
        mobile: "",
        email: ""
      });
      this.targetBlur(event);
    },
    btnDeleteOnClick(event) {
      this.targetBlur(event);

      ConfirmDialog(
        this.$i18n.t("common.dialog.deleteConfirmation.title"),
        this.$i18n.t("common.dialog.deleteConfirmation.message"),
        () => {
          this.removeSelectedRecords()
        });
    },
    btnImportOnClick(event) {
      this.uploadDialog.display = true;
      this.targetBlur(event);
    },
    removeSelectedRecords() {
      let table = this.table;
      let selection = table.selectionList;
      table.list = table.list.filter(item => {
        for (var element of selection) {
          if (element == item) { return false; }
        }
        return true;
      });
      this.$store.commit("updateList", table.list)
    },

    // Tablex
    selectionChange(selection) {
      this.table.selectionList = selection;
    },
    rowEdit(event, index, row) {
      this.openDialog(false, row);
      this.recordDialog.originStaff = row;
      this.targetBlur(event);
    },
    rowDelete(event, index) {
      this.targetBlur(event);
      ConfirmDialog(
        this.$i18n.t("common.dialog.deleteConfirmation.title"),
        this.$i18n.t("common.dialog.deleteConfirmation.message"),
        () => {
          this.table.list.splice(index, 1)
          this.$store.commit("updateList", this.table.list)
        });
    },
    async btnSubmitOnClick() {
      let list = JSON.parse(JSON.stringify(this.table.list));
      if (this.table.selectionList.length > 0) {
        list = JSON.parse(JSON.stringify(this.table.selectionList))
      }
      if (!list.every((obj) => { return obj.errors.length == 0 })) {
        ErrorMessage(this.$i18n.t("exceptions.invalidRecord"))
        return;
      }
      ConfirmDialog(this.$i18n.t("company.dialog.submitConfirmation.title"), this.$i18n.t("company.dialog.submitConfirmation.message"),
        async () => {
          this.tableLoading(true);
          this.disableSubmitBtn(true);
          await this.sleep(1000);
          // let current = this.$store.getters.getList
          // this.table.list.forEach(obj =>{
          //   obj.status = "Active"
          //   obj.updtime = moment().format("YYYY-MM-DD HH:mm:ss")
          //   obj.expiryDate = moment().add(7,"d").endOf("day").format("YYYY-MM-DD HH:mm:ss")
          //   obj.formType = this.$router.currentRoute.value.name == "CompanyNew" ? "New" : "Change"
          // });
          // let list = current.concat(this.table.list)
          let dataObj = list.map((obj) => {
            delete obj.errors
            return obj
          });
          registration.postSave(
            this.$store.getters.companyJWT,
            this.$router.currentRoute.value.name == "CompanyNew" ? 1 : 2,
            dataObj
          ).then(() => {
            if (this.table.selectionList.length > 0) {
              this.removeSelectedRecords();
            } else {
              this.table.list = [];
              this.$store.commit("clearList")
            }
            SuccessMessage(this.$i18n.t('company.message.success.submit'));
          }).catch((err) => {
            if (Object.prototype.hasOwnProperty.call(err, "response")) {
              let locale = this.$i18n.locale;
              for (const [key, value] of Object.entries(err.response.data)) {
                this.table.list = this.table.list.map((obj) => {
                  if (obj.name == list[key].name && obj.mobile == list[key].mobile && obj.email == list[key].email) {
                    value.forEach((errObj) => {
                      obj.errors.push(errObj.Message[locale.charAt(0).toUpperCase() + locale.slice(1)])
                    })
                  }
                  return obj;
                })
              }
              this.$store.commit("updateList", this.table.list)
            } else {
              common.handleCompanyException(err)
            }
            // common.handleCompanyException(err)
          }).finally(() => {
            this.tableLoading(false);
            this.disableSubmitBtn(false);
          })
        });
    },
    tableLoading(flag) {
      this.table.loading = flag;
    },
    disableSubmitBtn(flag) {
      this.table.submitBtnDisable = flag;
    },

    // Record Dialog
    openDialog(isAdd, staff) {
      let dialog = this.recordDialog;
      dialog.display = true;
      dialog.title = this.$i18n.t(`company.dialog.staffDialog.title.${isAdd ? "add" : "edit"}`);
      dialog.text = this.$i18n.t("company.dialog.staffDialog.message");
      dialog.isAdd = isAdd;
      Object.assign(dialog.staff, staff);
    },
    async dialogConfirm() {
      let dialog = this.recordDialog;
      this.v$.recordDialog.staff.$reset();
      let validateRes = await this.v$.recordDialog.staff.$validate();
      if (validateRes) {
        if (dialog.isAdd) {
          if (this.table.list.find((obj) => {
            return obj.name == dialog.staff.name && obj.mobile == dialog.staff.mobile && obj.email == dialog.staff.email
          })) {
            WarningAlert(this.$i18n.t("company.dialog.duplicatedRecord.message"))
            return;
          }
          this.table.list.push(JSON.parse(JSON.stringify(dialog.staff)));
        } else {
          dialog.staff.errors = [];
          Object.assign(dialog.originStaff, dialog.staff);
        }
        this.$store.commit("updateList", this.table.list);
        this.dialogClose(this.recordDialog);
      }
    },
    handleSubmit(event) {
      if (event.keyCode == 13) {
        this.dialogConfirm();
        this.targetBlur(event);
      }
    },

    // Upload Dialog
    onUploadDialogShow() {
      this.$nextTick(() => {
        this.uploadDialog.fileList = [];
        //this.$refs.upload.clearFiles();
      });
    },
    handleChange(files, fileList) {
      let lastIndex = fileList.length - 1;
      let fileFormat = fileList[lastIndex].raw.name.split(".").pop();
      if (fileFormat != "xlsx" && fileFormat != "xls") {
        ErrorMessage(this.$i18n.t('company.message.error.invalidFile'))
        fileList.pop();
        return;
      }

      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }

      this.uploadDialog.fileList = fileList;
    },
    handleUpload() {
      return false
    },
    uploadDialogConfirm() {
      let fileList = this.uploadDialog.fileList;
      if (fileList.length < 1) {
        WarningMessage(this.$i18n.t('company.message.warning.noFileFound'))
        return;
      }
      let fileFormat = fileList[0].raw.name.split(".").pop();
      if (fileFormat != "xlsx" && fileFormat != "xls") {
        ErrorMessage(this.$i18n.t('company.message.error.invalidFile'))
        return;
      }
      ReadExcel(fileList[0].raw, { range: "B20:D120" }, this.readExcelCallback);
    },
    async readExcelCallback(json) {
      this.tableLoading(true);
      let list = [];
      let duplicateCount = 0;
      for (var item of json) {
        // let values = Object.values(item)
        let staff = this.initStaffObj();
        staff.name = item["Staff Name (Full Name in English)"] ? item["Staff Name (Full Name in English)"].toString().trim().toUpperCase() : "";
        staff.mobile = item["Mobile Number"] ? item["Mobile Number"].toString().trim() : "";
        staff.email = item["Email Address"] ? item["Email Address"].toString().trim().toLowerCase() : "";
        if (list.filter((obj) => {
          return obj.name == staff.name && obj.mobile == staff.mobile && obj.email == staff.email
        }).length > 0) {
          duplicateCount += 1;
          continue;
        }
        this.uploadDialog.staff = staff;
        let staffValidation = this.v$.uploadDialog.staff;
        let validateRes = await staffValidation.$validate();
        if (!validateRes) {
          staff.errors = staffValidation.$errors.map((obj) => {
            let field = this.th(obj.$property);
            return `${field}: ${obj.$message}`;
          });
        }
        list.push(staff);
      }

      await this.sleep(500);

      // let mergedList = this.table.list.concat(list);
      if (list.length < 100) {
        this.table.list = list;
        // if (duplicateCount > 0) WarningMessage(this.$i18n.t('company.message.warning.duplicateFile', duplicateCount, { num: duplicateCount }));
        if (duplicateCount > 0) WarningMessage(i18nPluralizationBase('company.message.warning.duplicateFile', { num: duplicateCount }));
        this.$store.commit("updateList", list);

      } else {
        // ErrorMessage(this.$i18n.t('company.message.error.exceedRecordLimit', 100, { num: 100 }))
        ErrorMessage(i18nPluralizationBase('company.message.error.exceedRecordLimit', { num: 100 }));
      }

      this.$refs.upload.submit();
      this.tableLoading(false);
      this.dialogClose(this.uploadDialog);
    },

    // common
    initStaffObj() {
      return {
        name: null,
        mobile: null,
        email: null,
        errors: []
      }
    },
    targetBlur(event) {
      event.currentTarget.blur();
    },
    dialogClose(dialog) {
      dialog.display = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    test() {
      console.log(this);
    },
    loadData() {
      this.table.list = this.$store.state.company.list;
    },
    tableRowClassName({ row }) {
      if (!row) return "";
      return row.errors.length > 0 ? "error-row" : ""
    },
    isInvalid(invalid, extraClass = "") {
      return common.isInvalid(invalid, extraClass)
    },
    triggerValidate(event, source, type) {
      common.triggerValidate(event, source, type)
    }
  },
  beforeRouteLeave(to) {
    if (this.table.list.length > 0) {
      ConfirmDialog(
        this.$i18n.t("company.dialog.exitConfirmation.title"),
        this.$i18n.t("company.dialog.exitConfirmation.message"),
        () => {
          this.table.list = [];
          this.$store.commit("clearList")
          this.$router.push(to)
        },
        () => { },
        () => { },
        {
          showClose: false
        }
      );
      return false;
    }
  }
}
</script>
