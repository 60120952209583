import http from "@/http"

let modulePath = "master";
const get = function(locale, path, config = {}) {
  return http.get(`${locale}/${modulePath}/${path}`, config);
}

export default  {
  getDistricts(locale) {
    return get(locale, "districts")
  },
  getRegions(locale) {
    return get(locale, "regions")
  },
  getCompanies(locale) {
    return get(locale, "companies")
  },
  getCompanyByCode(locale, code) {
    return get(locale, "company", {
      params: {
        code: code
      }
    })
  },
  getCountries(locale) {
    return get(locale, "countries")
  },
  getCountryByCode(locale, code) {
    return get(locale, "country", {
      params: {
        code: code
      }
    })
  },
  getIsMaintenance() {
    return get("en", "maintenance")
  }
}
