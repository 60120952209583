<template>
  <el-container v-loading="loading" element-loading-background="#ffffff"
    :element-loading-text="$t('common.loading.default')">
    <el-header class="header">
      <el-row>
        <el-col :lg="3" :sm="5" :xs="6" class="logo">
          <img style="float:left;height: 40px;" src="./assets/AEL_Logo.png" />
          <!-- <div alt="MTR" href="https://www.mtr.com.hk" /> -->
        </el-col>
        <el-col :lg="19" :sm="16" :xs="14" class="title">
          <h2 class="title">{{ $t("title") }}</h2>
        </el-col>
        <el-col :lg="2" :sm="3" :xs="4" class="lang">
          <el-button round @click="switchLang">{{ $t("lang") }}</el-button>
        </el-col>
      </el-row>
    </el-header>
    <p class="xs-title">
      <span>{{ $t("title") }}</span>
    </p>
    <el-main>
      <router-view />
    </el-main>
    <el-footer>
      {{ $t("copyright") }}
    </el-footer>
  </el-container>
</template>

<script>
// import health from "./http/apis/health";
import master from "./http/apis/master";
import store from "@/store";
import "@/style/common.css";
import file from "./http/apis/file";

export default {
  name: "App",
  data() {
    return {
      loading: true
    }
  },
  methods: {
    switchLang() {
      let router = this.$router;
      let currentRoute = router.currentRoute.value;
      router.push({
        name: router.name,
        params: {
          locale: this.$i18n.locale == "en" ? "ch" : "en"
        },
        query: currentRoute.query
      });
    }
  },
  async beforeMount() {
    try {
      let config = await file.geti18nFile("config.json", window.location.origin);
      if (config.status == 200) {
        // console.log(console.log(`Override: ${config.data.override}`));
        if (config.data.override) {
          let locales = ["en", "ch"];
          for (let i = 0; i < locales.length; i++) {
            let langObj = await file.geti18nFile(`${locales[i]}.json`, window.location.origin);
            if (langObj.status == 200) {
              if (langObj.data) {
                // console.log(`Overriding: ${locales[i]}`)
                this.$i18n.setLocaleMessage(locales[i], langObj.data);
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
    }

    master.getIsMaintenance().then((res) => {
      if (res.data) {
        window.location.href = "https://www.mtr.com.hk/maintenance_airport_staff_travel_scheme.html";
      }
      // this.loading=false;
    }).catch(() => {
      window.location.href = "https://www.mtr.com.hk/maintenance_airport_staff_travel_scheme.html";
      // health.getHealthCheck().catch((err)=> {
      //   console.log(err)
      // }).finally(() => {
      //   this.loading=false;
      // })
    }).finally(() => {
      this.loading = false;
    });
    store.commit("updateIsMobile", window.matchMedia("(pointer:coarse)").matches);
  }
};
</script>
