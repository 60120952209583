import { createRouter, createWebHistory } from "vue-router"
import constants from "@/libs/constants.js"
import $i18n from "@/language/index.js"
import CompanyNew from "@/views/company/New.vue"

let companyPath = "/:locale/company"
let personalPath = "/:locale/personal"
const routes = [
  {
    path: "/",
    redirect: {
      name: "Personal",
      params: {
        locale: constants.locale.en
      }
    }
  },
  {
    name: "Home",
    path: "/:locale",
    redirect: to => {
      let locale = to.params.locale
      let locales = constants.locale
      let i18n = $i18n.global
      let path = to.path
      if (Object.prototype.hasOwnProperty.call(locales, locale)) {
        i18n.locale = locale
      } else {
        i18n.locale = locales.en
        path = locale
        // path = `/${locales.en}${to.path}`
      }
      return {
        name: "Process",
        params: {
          locale: i18n.locale,
          part: path
        },
        query: to.query
      }
    }
  },
  {
    name: "Process",
    path: "/:locale/:part",
    redirect: to => {
      let part = to.params.part
      let obj = {
        params: {
          locale: to.params.locale
        },
        query: to.query
      }
      switch (part) {
        case "process":
        case "cancel":
          obj.name = `StepPayment${part.charAt(0).toUpperCase() + part.slice(1)}`
          if (Object.prototype.hasOwnProperty.call(to.query, "token")) {
            obj.query.paymentToken = to.query.token
            delete obj.query.token
          }
          break
        case "company":
          obj.name = "Company"
          break
        default:
          obj.name = "Personal"
          break
      }
      return obj
    }
  },
  {
    name: "CompanyLogin",
    path: `${companyPath}/login`,
    component: () => import("@/views/company/Login.vue"),
    redirect: { name: "CompanyLoginEmail" },
    children: [
      {
        name: "CompanyLoginEmail",
        path: "email",
        component: () => import("@/views/company/Email.vue")
      },
      {
        name: "CompanyLoginChallenge",
        path: "challenge/:companyCode/:mail",
        component: () => import("@/views/company/Challenge.vue")
      }
    ]
  },
  {
    name: "CompanyAgreement",
    path: `${companyPath}/agreement`,
    component: () => import("@/views/company/Agreement.vue")
  },
  {
    name: "Company",
    path: companyPath,
    component: () => import("@/views/company/Company.vue"),
    redirect: { name: "CompanyProfile" },
    children: [
      {
        name: "CompanyProfile",
        path: "",
        component: () => import("@/views/company/Profile.vue")
      },
      {
        name: "CompanyNew",
        path: "new",
        component: CompanyNew
      },
      {
        name: "CompanyChange",
        path: "change",
        component: CompanyNew
      },
      {
        name: "CompanyEnquiry",
        path: "enquiry",
        component: () => import("@/views/company/Enquiry.vue")
      },
      // {
      // 	name: "CompanyStaff",
      // 	path: "staff",
      // 	// component: () => import("@/views/company/CompanyStaff.vue")
      // 	component: () => import("@/views/company/Enquiry.vue")
      // },
      {
        name: "staffTerminationRecord",
        path: "termination",
        component: () => import("@/views/company/Termination.vue")
      },
      {
        path: ":catchAll(.*)",
        redirect: { name: "CompanyProfile" }
      }
    ]
  },
  {
    name: "Personal",
    path: personalPath,
    component: () => import("@/views/personal/Personal.vue"),
    redirect: { name: "StepStart" },
    meta: { type: "Personal" },
    children: [
      {
        name: "StepStart",
        path: "",
        component: () => import("@/views/personal/steps/Start.vue")
      },
      {
        name: "StepAgreement",
        path: "agreement",
        component: () => import("@/views/personal/steps/Agreement.vue")
      },
      {
        name: "StepForm",
        path: "form",
        component: () => import("@/views/personal/steps/Form.vue")
      },
      {
        name: "StepConfirm",
        path: "confirm",
        component: () => import("@/views/personal/steps/Confirmation.vue")
      },
      {
        name: "StepPayment",
        path: "payment",
        component: () => import("@/views/personal/steps/Payment.vue")
      },
      {
        name: "StepPaymentProcess",
        path: "process",
        component: () => import("@/views/personal/steps/PaymentProcess.vue")
      },
      {
        name: "StepPaymentLanding",
        path: "landing",
        component: () => import("@/views/personal/steps/PaymentLanding.vue")
      },
      {
        name: "StepComplete",
        path: "complete",
        component: () => import("@/views/personal/steps/Complete.vue")
      },
      {
        name: "StepPaymentCancel",
        path: "cancel",
        component: () => import("@/views/personal/steps/PaymentCancel.vue")
      }
      // {
      //   path: ":catchAll(.*)",
      //   redirect: { name: "StepStart" }
      // }
    ]
  },
  {
    name: "PersonalUnauthorized",
    path: `${personalPath}/401`,
    component: () => import("@/views/personal/401.vue")
  },
  {
    name: "PersonalForbidden",
    path: `${personalPath}/403`,
    component: () => import("@/views/personal/403.vue")
  },
  {
    name: "PersonalAppInfo",
    path: `${personalPath}/info`,
    component: () => import("@/views/personal/ApplicationInfo.vue")
  }
  // {
  //   path: "/:locale/process",
  //   // redirect: { name: "StepPaymentProcess" },
  //   redirect: to => {
  //     // the function receives the target route as the argument
  //     // we return a redirect path/location here.
  //     let obj = { name: "StepPaymentProcess", params: to.params, query: to.query }
  //     if (Object.prototype.hasOwnProperty.call(to.query, "token")) {
  //       obj.query.paymentToken = to.query.token;
  //       delete obj.query.token;
  //     }
  //     return obj
  //   }
  // },
  // {
  //   path: "/:locale/cancel",
  //   redirect: to => {
  //     // the function receives the target route as the argument
  //     // we return a redirect path/location here.
  //     let obj = { name: "StepPaymentCancel", params: to.params, query: to.query }
  //     if (Object.prototype.hasOwnProperty.call(to.query, "token")) {
  //       obj.query.paymentToken = to.query.token;
  //       delete obj.query.token;
  //     }
  //     return obj
  //   }
  //   // redirect: { name: "StepPaymentCancel" },
  // },
  // {
  //   path: "/:locale/:catchAll(.*)",
  //   name: "404",
  //   component: () => import("@/views/404.vue")
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let locale = to.params.locale
  let locales = constants.locale
  let i18n = $i18n.global

  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    i18n.locale = locale
    next()
  } else {
    i18n.locale = locales.en
    next({
      path: `/${locales.en}${to.path}`
    })
  }
})

export default router
