import { ElLoading } from 'element-plus'
import $i18n from "../language/index.js";

let i18n = $i18n.global;
export function create(type="default", customClass = "", background="#rgba(0,0,0,.9)" ) {
  return ElLoading.service({
    text: i18n.t(`common.loading.${type}`),
    customClass: customClass,
    background: background
  })
}